/**
 * @generated SignedSource<<2937bfd5649ec935207944dd7fc3214a>>
 * @relayHash cf6709f0d6de8849035ba88e82925cdf
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-misc/476.0.0-2024-08-20T10:46:32.636Z/ModifyPortfolioItemsMutation

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PortfolioItemsAction = "ADD" | "EDIT" | "REMOVE" | "REORDER" | "%future added value";
export type PortfolioType = "CART" | "HEART" | "%future added value";
export type ModifyPortfolioItemsInput = {
  action: PortfolioItemsAction;
  clientMutationId?: string | null;
  endPortfolioItemId?: string | null;
  itemsIds?: ReadonlyArray<string | null> | null;
  note?: string | null;
  portfolioId?: string | null;
  portfolioItemsIds?: ReadonlyArray<string | null> | null;
  portfolioType?: PortfolioType | null;
  startPortfolioItemId?: string | null;
  targetPortfolioItemId?: string | null;
  userId: string;
};
export type ModifyPortfolioItemsMutation$variables = {
  input: ModifyPortfolioItemsInput;
};
export type ModifyPortfolioItemsMutation$data = {
  readonly modifyPortfolioItems: {
    readonly portfolio: {
      readonly id: string;
    } | null;
  } | null;
};
export type ModifyPortfolioItemsMutation = {
  response: ModifyPortfolioItemsMutation$data;
  variables: ModifyPortfolioItemsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ModifyPortfolioItemsPayload",
    "kind": "LinkedField",
    "name": "modifyPortfolioItems",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PortfolioV2",
        "kind": "LinkedField",
        "name": "portfolio",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ModifyPortfolioItemsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ModifyPortfolioItemsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "app-buyer-misc/476.0.0-2024-08-20T10:46:32.636Z/ModifyPortfolioItemsMutation",
    "metadata": {},
    "name": "ModifyPortfolioItemsMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "94aed0469fe8b3138bef1ac19d488d29";

export default node;
